<script setup>
import Button from '@/Components/Button/Button.vue';
import Pagination from '@/Components/Pagination/PaginationFE.vue';
import Table from '@/Components/Table/Table.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';
import { useForm } from '@inertiajs/vue3';
import { useDebounceFn } from '@vueuse/core';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const route = inject('route');
const props = defineProps({
    query: Object,
    shifts: Object,
    lastShiftDate: String,
    statusOptions: Object,
    positionOptions: Object,
    locationOptions: Object,
    flexerOptions: Object,
});

const form = useForm({
    search: props.query.search ?? '',
    status: props.query.status ?? '',
    location: props.query.location ?? '',
    flexer: props.query.flexer ?? '',
    position_id: props.query.position_id ?? '',
    from: props.query.from ?? '',
    to: props.query.to ?? '',
    orderBy: props.query.orderBy ?? 'start',
    orderDir: props.query.orderDir ?? 'desc',

    page: props.query.page ?? 1,
});

const getData = (resetPage = true) => {
    form.page = resetPage ? 1 : form.page;

    form.get(route('staff.shifts.index'), {
        preserveState: true,
        preserveScroll: true,
        only: ['shifts', 'query'],
    });
};
const debouncedGetData = useDebounceFn(() => getData(), 300);

const tableSettings = [
    {
        label: t('Date {model}', { model: 'shift' }),
        orderBy: 'start',
        width: '10%',
        cell: 'CustomHtml',
    },
    {
        label: t('Details {model}', { model: 'shift' }),
        from: 'lg',
        width: '15%',
        cell: 'CustomHtml',
    },
    { label: t('All applications'), from: 'lg', width: '10%' },
    { label: t('Employer'), from: 'md', orderBy: 'employer', width: '20%' },
    { label: t('Fl@xr'), orderBy: 'flexer', width: '20%' },
    { label: t('Type'), width: '10%', from: 'lg', orderBy: 'flexer_type' },
    { label: t('Position'), orderBy: 'position', width: '10%' },
    { label: t('Status'), orderBy: 'status', width: '10%' },
    { label: '', cell: 'Arrow', width: '5%' },
];

const tableData = computed(() =>
    props.shifts.data.map((shift) => {
        return {
            id: shift.id, // used as vue key if set
            // route: route('staff.shifts.show', shift.id), // if set adds arrow link to row
            colorCode: shift.color_code, // used to set row color accent
            rowData: [
                shift.date,
                shift.detail,
                shift.applications_count,
                shift.location_name,
                shift.selected_flexer,
                shift.type,
                shift.position,
                shift.status,
                route('staff.shifts.show', shift.id),
            ],
        };
    })
);

const setPage = (e) => {
    form.page = e;
    getData(false);
};
</script>

<template>
    <StaffLayout title="Shifts">
        <div class="flex justify-end w-full mb-4">
            <Button
                blue
                icon="/icon/download-excel.svg"
                type="button"
                asA
                class="ml-2"
                :href="route('staff.shifts.download')"
                :text="$t('Export')"
            />
        </div>
        <div class="flex items-center w-full gap-4">
            <FormKit
                type="search"
                :placeholder="$t('Search')"
                name="search"
                prefix-icon="search"
                v-model="form.search"
                :value="form.search"
                @input="debouncedGetData"
            />

            <FormKit
                type="select"
                name="employer"
                :options="{
                    '': $t('All {attribute}', { attribute: $t('employers') }),
                    ...locationOptions,
                }"
                select-icon="down"
                v-model="form.location"
                :value="form.location"
                @input="getData"
            />

            <FormKit
                type="select"
                name="flexer"
                :options="{
                    '': $t('All {attribute}', { attribute: $t(`Fl@xr's`) }),
                    ...flexerOptions,
                }"
                select-icon="down"
                v-model="form.flexer"
                :value="form.flexer"
                @input="getData"
            />
            <FormKit
                type="select"
                select-icon="down"
                name="position"
                :options="{
                    '': $t('All {attribute}', { attribute: $t('positions') }),
                    ...positionOptions,
                }"
                v-model="form.position_id"
                :value="form.position_id"
                @input="getData"
            />
            <FormKit
                type="select"
                name="status"
                select-icon="down"
                :options="{
                    '': $t('All {attribute}', { attribute: $t('statuses') }),
                    ...statusOptions,
                }"
                v-model="form.status"
                :value="form.status"
                @input="getData"
            />
        </div>
        <div class="flex items-center w-full gap-4">
            <FormKit
                type="date"
                name="from"
                v-model="form.from"
                @input="getData"
                :label="$t('From')"
                :max="props.lastShiftDate"
            />
            <FormKit
                type="date"
                name="to"
                v-model="form.to"
                @input="getData"
                :label="$t('To')"
                :max="props.lastShiftDate"
            />
        </div>

        <template v-if="typeof shifts !== 'undefined' && shifts.data.length > 0">
            <Table
                class="mb-8"
                :loading="form.processing"
                :tableSettings="tableSettings"
                :data="tableData"
                :footerFaded="query.search != '' && query.search != null"
                v-model:orderBy="form.orderBy"
                @update:orderBy="getData()"
                v-model:orderDir="form.orderDir"
                @update:orderDir="getData()"
            />
            <Pagination
                v-if="typeof shifts !== 'undefined'"
                :links="shifts.links"
                @setPage="setPage"
                :pageData="shifts"
                :hideTotal="query.search != '' && query.search != null"
            />
        </template>
        <template v-else>
            <div class="w-full px-4 py-3 overflow-hidden font-bold leading-tight bg-white rounded">
                {{ $t('No {model} found', { model: $t('shifts') }) }}
            </div>
        </template>
    </StaffLayout>
</template>
